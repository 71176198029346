import React from 'react';
import './ContactPage.css';

const ContactPage = () => {
  return (
    <div className="contact-container">
      <p className="contact-heading">Contact us</p>
      <h1 className="contact-email">anim@anim.llc</h1>
    </div>
  );
};

export default ContactPage;
